import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    getListaGraficoCustoTiposMultaAnual,
    getListaGraficoCustoMultaAnual,
    getListaGraficoCustoMultaAgrupado,
    getListaGraficoCustoReativoTipoMulta,
    getListaGraficoCustoMultaPorUnidadeAcumuladoAno,
    setFiltro,
} from "../analiseActions";

import { montarGraficoColumn } from "../../common/graficos/montarGraficoColumn.js";
import { montarGraficoPieAnual } from "../../common/graficos/montarGraficoPieAnual.js";
import { montarGraficoClusteredColumnGrupo } from "../../common/graficos/montarGraficoClusteredColumnGrupo.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";

function analiseMulta(props) {
    const {
        state,
        listar,
        filtro,
        listaGraficoCustoTiposMulta,
        listaGraficoCustoMulta,
        listaGraficoCustoMultaAgrupado,
        listarGraficoCustoReativoTipoMulta,
        listaGraficoCustoMultaPorUnidade,
        getListaGraficoCustoTiposMultaAnual,
        getListaGraficoCustoMultaAnual,
        getListaGraficoCustoMultaAgrupado,
        getListaGraficoCustoReativoTipoMulta,
        getListaGraficoCustoMultaPorUnidadeAcumuladoAno,
        setAguardando,
        setFiltro,
    } = props;

    const [graficoCustoTiposMultaAnual, setGraficoCustoTiposMultaAnual] = useState(null);
    const [graficoCustoMultaAnual, setGraficoCustoMultaAnual] = useState(null);
    const [graficoCustoMultaTipo, setGraficoCustoMultaTipo] = useState(null);
    const [graficoCustoReativoTipoMulta, setGraficoCustoReativoTipoMulta] = useState(null);
    const [graficoCustoAcumuladoAnualMultasPorUC, setGraficoCustoAcumuladoAnualMultasPorUC] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getListaGraficoCustoTiposMultaAnual(),
                getListaGraficoCustoMultaAnual(),
                getListaGraficoCustoMultaAgrupado(),
                getListaGraficoCustoReativoTipoMulta(),
                getListaGraficoCustoMultaPorUnidadeAcumuladoAno(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        atualizarTodosGraficos();
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoTiposMultaAnual();
        atualizarListaGraficoCustoMultaAnual();
        atualizarListaGraficoCustoMultaAgrupado();
        atualizarListaGraficoCustoReativoTipoMulta();
        atualizarListaGraficoCustoAcumuladoAnualMultasPorUC();
    }
    function desmontarTodosGraficos() {
        if (graficoCustoTiposMultaAnual) graficoCustoTiposMultaAnual.dispose();
        if (graficoCustoMultaAnual) graficoCustoMultaAnual.dispose();
        if (graficoCustoMultaTipo) graficoCustoMultaTipo.dispose();
        if (graficoCustoReativoTipoMulta) graficoCustoReativoTipoMulta.dispose();
        if (graficoCustoAcumuladoAnualMultasPorUC) graficoCustoAcumuladoAnualMultasPorUC.dispose();
    }

    function atualizarListaGraficoCustoMultaAnual() {
        const graficoCustoMultaAnualAtualizado = montarGraficoColumn({
            state,
            component: graficoCustoMultaAnual,
            nome: "graficoCustoMultaAnual",
            dados: listaGraficoCustoMulta,
            category: filtro.multas_ano_safra ? "ano_inicio_fim_safra" : "ano",
            value: "valor",
            titulo: `Custo Total de Multas por ${filtro.multas_ano_safra ? "Safra" : "Ano"} (R$)`,
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoMultaAnual(graficoCustoMultaAnualAtualizado);
    }

    function atualizarListaGraficoCustoTiposMultaAnual() {
        const graficoCustoTiposMultaAnualAtualizado = montarGraficoClusteredColumnGrupo(
            state,
            graficoCustoTiposMultaAnual,
            "graficoCustoTiposMultaAnual",
            listaGraficoCustoTiposMulta,
            "Custo Total Por Tipo de Multa (R$)",
            "R$",
            ""
        );
        setGraficoCustoTiposMultaAnual(graficoCustoTiposMultaAnualAtualizado);
    }

    function atualizarListaGraficoCustoMultaAgrupado() {
        const graficoCustoMultaTipoAtualizado = montarGraficoPieAnual(
            state,
            graficoCustoMultaTipo,
            "graficoCustoMultaTipo",
            listaGraficoCustoMultaAgrupado,
            "Custo Geral por Tipo de Multa (R$)",
            "R$",
            ""
        );
        setGraficoCustoMultaTipo(graficoCustoMultaTipoAtualizado);
    }

    function atualizarListaGraficoCustoReativoTipoMulta() {
        const graficoCustoReativoTipoMultaAtualizado = montarGraficoPieAnual(
            state,
            graficoCustoReativoTipoMulta,
            "graficoCustoReativoTipoMulta",
            listarGraficoCustoReativoTipoMulta,
            "Custo de Multa de Reativo por Tipo (R$)",
            "R$",
            ""
        );
        setGraficoCustoReativoTipoMulta(graficoCustoReativoTipoMultaAtualizado);
    }

    function atualizarListaGraficoCustoAcumuladoAnualMultasPorUC() {
        const graficoCustoAcumuladoAnualMultasPorUCAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoAcumuladoAnualMultasPorUC,
            chartDivId: "graficoCustoAcumuladoAnualMultasPorUC",
            category: "nome_unidade_consumidora",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: [...listaGraficoCustoMultaPorUnidade].sort((a, b) => a.valor - b.valor),
            titulo: "Custo Acumulado Anual de Multas por UC (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoAcumuladoAnualMultasPorUC(graficoCustoAcumuladoAnualMultasPorUCAtualizado);
    }

    const listaTabelaCustoTotalMultas = graficoCustoMultaAnual ? graficoCustoMultaAnual._data : [];
    const periodo = Object.keys(listaTabelaCustoTotalMultas[0] || {})[0];

    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoMultaAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}></Th>
                                    {listaTabelaCustoTotalMultas.map((item) => (
                                        <Th alignCenter>{item[periodo]}</Th>
                                    ))}
                                </Tr>
                            </THead>
                            <TBody>
                                <Tr>
                                    <Td color="#fff">R$ Total</Td>
                                    {listaTabelaCustoTotalMultas.map((item) => (
                                        <Td key={item[periodo]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">R$ Multa</Td>
                                    {listaTabelaCustoTotalMultas.map((item) => (
                                        <Td key={item[periodo]} alignCenter color="#fff">
                                            {parseFloat(item.valor || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">Rel. %</Td>
                                    {listaTabelaCustoTotalMultas.map((item) => (
                                        <Td key={item[periodo]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura > 0 ? (item.valor * 100) / item.valor_fatura : 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                            %
                                        </Td>
                                    ))}
                                </Tr>
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: true /*!buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante")*/ ? "" : "none",
                }}
            >
                <ContentCardHeader style={{ backgroundColor: "#fff" }}>
                    <Row>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_comparativo_tipo_multa
                                            ? `${String(filtro.competencia_inicial_comparativo_tipo_multa).substring(0, 4)}/${String(
                                                  filtro.competencia_inicial_comparativo_tipo_multa
                                              ).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_comparativo_tipo_multa: value
                                                ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_comparativo_tipo_multa
                                            ? `${String(filtro.competencia_final_comparativo_tipo_multa).substring(0, 4)}/${String(
                                                  filtro.competencia_final_comparativo_tipo_multa
                                              ).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_comparativo_tipo_multa: value
                                                ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="12 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={() => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_comparativo_tipo_multa: null,
                                        competencia_final_comparativo_tipo_multa: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <Row>
                        <Grid cols="6 6 6 6">
                            <div id="graficoCustoMultaTipo" style={{ width: "100%", paddingBlock: 20, height: "300px" }}></div>
                        </Grid>
                        <Grid cols="6 6 6 6">
                            <div id="graficoCustoReativoTipoMulta" style={{ width: "100%", paddingBlock: 20, height: "300px" }}></div>
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoCustoAcumuladoAnualMultasPorUC"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: (listaGraficoCustoMultaPorUnidade || []).length * 50 < 400 ? 400 : listaGraficoCustoMultaPorUnidade.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	listaGraficoCustoTiposMulta: state.analise.listaGraficoCustoTiposMulta,
	listaGraficoCustoMulta: state.analise.listaGraficoCustoMulta,
	listaGraficoCustoMultaAgrupado: state.analise.listaGraficoCustoMultaAgrupado,
	listarGraficoCustoReativoTipoMulta: state.analise.listarGraficoCustoReativoTipoMulta,
	listaGraficoCustoMultaPorUnidade: state.analise.listaGraficoCustoMultaPorUnidade,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getListaGraficoCustoMultaAnual,
			getListaGraficoCustoTiposMultaAnual,
			getListaGraficoCustoMultaAgrupado,
			getListaGraficoCustoReativoTipoMulta,
			getListaGraficoCustoMultaPorUnidadeAcumuladoAno,
			setFiltro,
		},
		dispatch
	);
const AnaliseMulta = connect(mapStateToProps, mapDispatchToProps)(analiseMulta);
export { AnaliseMulta };
