import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    listaDemandaContratoTipo: [],
    listaMeses: [
        { id: 1, valor: "Janeiro" },
        { id: 2, valor: "Fevereiro" },
        { id: 3, valor: "Março" },
        { id: 4, valor: "Abril" },
        { id: 5, valor: "Maio" },
        { id: 6, valor: "Junho" },
        { id: 7, valor: "Julho" },
        { id: 8, valor: "Agosto" },
        { id: 9, valor: "Setembro" },
        { id: 10, valor: "Outubro" },
        { id: 11, valor: "Novembro" },
        { id: 12, valor: "Dezembro" },
    ],
    filtro: {
        ano: new Date().getFullYear(),
        id_cliente: null,
        id_unidade_consumidora: null,
        id_tipo_contrato_demanda: 1, //padrão traz somente fora ponta
        encerrado: false,
    },
    listaDemandaCompetencia: [],
    modoTelaDemandaCompetencia: "lista",
    listaAlteracaoDemandaCompetencia: [],
    listaHistorico: [],
    listaAgendamento: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DEMANDA_CONTRATO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "DEMANDA_CONTRATO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };
        case "DEMANDA_CONTRATO_FILTRO":
            return { ...state, filtro: action.payload };

        case "DEMANDA_CONTRATO_LISTADO":
            const lista = action.payload.data.map((item) => {
                const periodo_teste = (item.periodo_teste || []).map((periodo) => JSON.parse(periodo));
                if (periodo_teste.length) {
                    const competencia_inicio_periodo_teste = periodo_teste[0].mes + "/" + periodo_teste[0].ano;
                    const competencia_fim_periodo_teste =
                        periodo_teste[periodo_teste.length - 1].mes + "/" + periodo_teste[periodo_teste.length - 1].ano;
                    return {
                        ...item,
                        competencia_inicio_periodo_teste,
                        competencia_fim_periodo_teste,
                        periodo_teste,
                    };
                }
                return { ...item, periodo_teste };
            });
            return {
                ...state,
                lista,
            };

        case "DEMANDA_CONTRATO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "DEMANDA_CONTRATO_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaDemandaContratoTipo: action.payload.data,
            };

        case "DEMANDA_CONTRATO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: filtrarUcPorDataDesligamento(action.payload.data || []),
            };

        case "DEMANDA_COMPETENCIA_LISTADO":
            return {
                ...state,
                listaDemandaCompetencia: action.payload.data,
            };

        case "DEMANDA_COMPETENCIA_LISTA_ALTERACAO":
            return {
                ...state,
                modoTelaDemandaCompetencia: action.payload.modo,
                listaAlteracaoDemandaCompetencia: action.payload.lista,
                listaHistorico: action.payload.modo == "lista" ? [] : state.listaHistorico,
            };

        case "DEMANDA_COMPETENCIA_HISTORICO_LISTADO":
            return {
                ...state,
                listaHistorico: action.payload.data,
            };

        case "DEMANDA_CONTRATO_AGENDAMENTO_LISTADO":
            return {
                ...state,
                listaAgendamento: action.payload.data,
            };

        default:
            return state;
    }
};
