import React, { useState } from "react";
import Td from "./td";
import Th from "./th";
import ButtonTable from "./buttonTable";
import Button from "../button/button";
import "./newTr.css";

export default (props) => {
    const [isHovered, setHovered] = useState(false);
    //remove os nulos
    const botoes = props.botoes ? props.botoes.filter((item) => !!item) : [];
    const botoesIcone = botoes.filter((botao) => botao.icon || (botao.children && botao.event)); //quando usa mais de um ícone passa o elemento na prop children
    const botoesTexto = botoes.filter((botao) => !botao.icon && !botao.children); //se n tem icone nem childen é botao de texto.Ex: 'Importar'
    const spans = botoes.filter((item) => !item.event && !item.icon);

    //agrupa botoes em pares para cada par ficar abaixo do outro
    const paresBotoesComIcone = [];
    let par = [];
    for (let i = 0; i < botoesIcone.length; i++) {
        if (par.length < 2) {
            par.push(botoesIcone[i]);
        }
        if (par.length == 2) {
            paresBotoesComIcone.push(par);
            par = [];
        }
    }

    return (
        <tr
            className="linha-cores-intercaladas"
            style={{
                ...props.style,
                backgroundColor: props.backgroundColorTr,
                filter: isHovered ? (String(props.backgroundColorTr).toLocaleUpperCase() === "#FFFFFF" ? "brightness(0.95)" : "brightness(1.1)") : "",
                "--backgroundColorTd": props.backgroundColorTd || "",
                "--backgroundColorTr": props.backgroundColorTr || "",
            }}
            onMouseEnter={() => {
                setHovered(true);
            }}
            onClick={() => setHovered(!isHovered)}
            onMouseLeave={() => setHovered(false)}
        >
            {props.celulas ? (
                <>
                    {props.celulas}
                    {props.botoes ? (
                        <Td minWidth={botoes.length ? 100 : null}>
                            {/* RENDERIZA AS MENSAGENS no lugar dos botoes. Ex: 'Fatura isenta' */}
                            {spans.map((span, i) => (isHovered ? <div key={i}>{span.children}</div> : null))}
                            {/* RENDERIZA OS BOTÕES DE TEXTO */}
                            {botoesTexto.length ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "end",
                                        minWidth: "100%",
                                        marginBottom: 2,
                                        visibility: isHovered ? "visible" : "hidden",
                                    }}
                                >
                                    {botoesTexto.map((botao) => (
                                        <Button {...botao} />
                                    ))}
                                </div>
                            ) : null}
                            {/* RENDERIZA BOTOES PEQUENOS COM ICONE, ATÈ 3 em linha, 4 ou mais mostra pares um abaixo do outro */}
                            {botoesIcone.length < 4 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "end",
                                    }}
                                >
                                    {botoesIcone.map((botao, i) => (
                                        <ButtonTable key={i} {...botao} visible={isHovered} />
                                    ))}
                                </div>
                            ) : (
                                paresBotoesComIcone.map((par, i) => (
                                    <div
                                        key={i}
                                        style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            justifyContent: "end",
                                        }}
                                    >
                                        {par.map((botao, j) => (
                                            <ButtonTable key={j} {...botao} visible={isHovered} />
                                        ))}
                                    </div>
                                ))
                            )}
                        </Td>
                    ) : (
                        <Th sticky style={{ fontSize: "0.85rem" }} alignCenter></Th>
                    )}
                </>
            ) : (
                props.children
            )}
        </tr>
    );
};
